var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticClass:"ma-3",attrs:{"row":""}},[_c('v-flex',{staticClass:"pr-5",attrs:{"sm4":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('span',{staticClass:"headline"},[_vm._v("My Forest")])]),_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-btn-toggle',{staticClass:"mt-2",staticStyle:{"background":"transparent"},attrs:{"mandatory":""},model:{value:(_vm.jobSiteTreeToggle),callback:function ($$v) {_vm.jobSiteTreeToggle=$$v},expression:"jobSiteTreeToggle"}},[_c('v-btn',[_vm._v(" Job Sites ")]),_c('v-btn',[_vm._v(" Trees ")])],1)],1)],1),(!_vm.jobSiteTreeToggle)?_c('v-list',{staticStyle:{"background":"transparent","max-height":"80vh","overflow":"auto"}},_vm._l((_vm.filteredJobSites),function(site){return _c('v-list-item',{key:site.uuid},[_c('v-list-item-content',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"},on:{"click":function($event){return _vm.jobSiteClicked(site)}}},[(site.name)?_c('v-card-title',[_vm._v(" "+_vm._s(site.name)+" ")]):_c('v-card-title',[_c('i',[_vm._v("No Site Name")])]),_c('v-card-text',[_c('div',[_c('b',[_vm._v("Client:")]),_vm._v(" "+_vm._s(site.client_name))]),_c('div',[_c('b',[_vm._v("Address:")]),_vm._v(" "+_vm._s(site.address))]),_c('div',[_vm._v(" "+_vm._s(site.city)+", "+_vm._s(site.state)+" "+_vm._s(site.country)+" ")]),_c('div',[_c('b',[_vm._v("Active Jobs:")]),_vm._v(" "+_vm._s(site.numActiveJobs))])]),_c('v-card-actions',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg6":""}},[(_vm.allowClients)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                          name: 'ClientView',
                          params: {uuid: site.client_uuid},
                        })}}},[_vm._v(" View Client ")]):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","lg6":""}},[(_vm.allowJobSites)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push(
                          '/clients/view/' +
                            site.client_uuid +
                            '?tab=jobSites&&jobSiteID=' +
                            site.uuid
                        )}}},[_vm._v(" View Job Site ")]):_vm._e()],1)],1)],1)],1)],1)],1)}),1):_c('v-list',{staticStyle:{"background":"transparent","max-height":"80vh","overflow":"auto"}},_vm._l((_vm.trees),function(tree){return _c('v-list-item',{key:tree.uuid},[_c('v-list-item-content',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"},on:{"click":function($event){return _vm.treeClicked(tree)}}},[(tree.species)?_c('v-card-title',[_vm._v(_vm._s(tree.species.common_name || 'Not Available'))]):_c('v-card-title',[_vm._v("No Species Available")]),_c('v-card-text',[_c('div',[_c('b',[_vm._v("DBH: ")]),_vm._v(_vm._s(tree.dbh))]),_c('div',[_c('b',[_vm._v("Height: ")]),_vm._v(_vm._s(tree.height))]),_c('div',[_c('b',[_vm._v("Notes: ")]),_vm._v(_vm._s(tree.notes || 'None available.'))])]),_c('v-card-actions',[(_vm.allowClients)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'ClientView',
                      params: {uuid: tree.client_uuid},
                    })}}},[_vm._v(" View Client ")]):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'JobSiteView',
                      params: {uuid: tree.job_site_uuid},
                    })}}},[_vm._v(" View Job Site ")])],1)],1)],1)],1)}),1)],1),_c('v-flex',{attrs:{"sm8":""}},[(_vm.tenantSettings)?_c('inventory-map',{ref:"inventoryMap",attrs:{"jobsites":_vm.jobSites,"tenantSettings":_vm.tenantSettings,"trees":_vm.trees,"can_add_trees":false,"inventory_type":_vm.mapLayer}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }