<!-- ----------------------------------------------------------------------- -->
<!--
name    : MY FOREST VIEW

type    : view

uses    : inventory-map

route   : /home
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container fluid>
    <v-layout row class="ma-3">
      <v-flex sm4 class="pr-5">
        <v-layout wrap>
          <v-flex xs12 lg6>
            <span class="headline">My Forest</span>
          </v-flex>
          <v-flex xs12 lg6>
            <v-btn-toggle
              v-model="jobSiteTreeToggle"
              mandatory
              class="mt-2"
              style="background: transparent">
              <v-btn> Job Sites </v-btn>
              <v-btn> Trees </v-btn>
            </v-btn-toggle>
          </v-flex>
        </v-layout>
        <v-list
          v-if="!jobSiteTreeToggle"
          style="background: transparent; max-height: 80vh; overflow: auto">
          <v-list-item v-for="site in filteredJobSites" v-bind:key="site.uuid">
            <v-list-item-content>
              <v-card
                max-width="344"
                class="mx-auto"
                @click="jobSiteClicked(site)">
                <v-card-title v-if="site.name">
                  {{ site.name }}
                </v-card-title>
                <v-card-title v-else>
                  <i>No Site Name</i>
                </v-card-title>
                <v-card-text>
                  <div><b>Client:</b> {{ site.client_name }}</div>
                  <div><b>Address:</b> {{ site.address }}</div>
                  <div>
                    {{ site.city }}, {{ site.state }} {{ site.country }}
                  </div>
                  <div><b>Active Jobs:</b> {{ site.numActiveJobs }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-layout wrap>
                    <v-flex xs12 lg6>
                      <v-btn
                        v-if="allowClients"
                        @click="
                          $router.push({
                            name: 'ClientView',
                            params: {uuid: site.client_uuid},
                          })
                        "
                        text>
                        View Client
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 lg6>
                      <v-btn
                        v-if="allowJobSites"
                        @click="
                          $router.push(
                            '/clients/view/' +
                              site.client_uuid +
                              '?tab=jobSites&&jobSiteID=' +
                              site.uuid
                          )
                        "
                        text>
                        View Job Site
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          v-else
          style="background: transparent; max-height: 80vh; overflow: auto">
          <v-list-item v-for="tree in trees" v-bind:key="tree.uuid">
            <v-list-item-content>
              <v-card
                max-width="344"
                class="mx-auto"
                @click="treeClicked(tree)">
                <v-card-title v-if="tree.species">{{
                  tree.species.common_name || 'Not Available'
                }}</v-card-title>
                <v-card-title v-else>No Species Available</v-card-title>
                <v-card-text>
                  <div><b>DBH: </b>{{ tree.dbh }}</div>
                  <div><b>Height: </b>{{ tree.height }}</div>
                  <div><b>Notes: </b>{{ tree.notes || 'None available.' }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    v-if="allowClients"
                    @click="
                      $router.push({
                        name: 'ClientView',
                        params: {uuid: tree.client_uuid},
                      })
                    "
                    text>
                    View Client
                  </v-btn>
                  <v-btn
                    @click="
                      $router.push({
                        name: 'JobSiteView',
                        params: {uuid: tree.job_site_uuid},
                      })
                    "
                    text>
                    View Job Site
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex sm8>
        <inventory-map
          v-if="tenantSettings"
          v-bind:jobsites="jobSites"
          v-bind:tenantSettings="tenantSettings"
          v-bind:trees="trees"
          :can_add_trees="false"
          v-bind:inventory_type="mapLayer"
          ref="inventoryMap" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import InventoryMap from '@/components/inventory-map';
  import Clients from '@/services/Clients.service.js';
  import Jobs from '@/services/Jobs.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'MyForest',
    components: {
      'inventory-map': InventoryMap,
    },
    data() {
      return {
        allowClients: false,
        allowJobSites: false,
        jobSites: [],
        jobSiteTreeToggle: 0,
        mapLayer: 'jobSites',
        trees: [],
        tenantSettings: null,
        jobSitesHeaders: [
          {
            text: 'Client Name',
            value: 'client_name',
          },
          {
            text: 'Num Active Jobs',
            value: 'activeJobs',
          },
          {
            text: 'Address',
            align: 'left',
            value: 'address',
          },
          {
            text: 'City',
            value: 'city',
          },
          {
            text: 'State',
            value: 'state',
          },
          {
            text: 'Country',
            value: 'country',
          },
        ],
      };
    },
    computed: {
      filteredJobSites: function () {
        // console.log( 'jobsites', this.jobSites)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.jobSites.sort((a, b) => {
          var nameA = a.name ? a.name.toUpperCase() : null; // ignore upper and lowercase
          var nameB = b.name ? b.name.toUpperCase() : null; // ignore upper and lowercase
          if (nameA || nameA < nameB) {
            // console.log( -1 )
            return -1;
          }
          if (nameB || nameA > nameB) {
            // console.log( 1 )
            return 1;
          }
          return 0;
        });
      },
      //TLC- this function was causing a feedback loop with the inventory map, removed for now.
      // the inventory map modifies the trees prop internally whenever a tree card is clicked
      // this feeds back to the filteredTrees function, which then re-orders the tree prop again
      // and causes the inventory-map to re-render all map markers and disrupts the marker event.
      // needs a refactor, but the work required is significantly more than we can tackle short-term
      /*
    filteredTrees: function(){
      return this.trees.sort((a, b) => {
        var nameA = a.species ? a.species.common_name.toUpperCase() : ''; // ignore upper and lowercase
        var nameB = b.species ? b.species.common_name.toUpperCase() : ''; // ignore upper and lowercase
        if (nameA < nameB || nameB === '') {
          return -1;
        }
        if (nameA > nameB || nameA === '') {
          return 1;
        }
        return 0;
      })
    }
    */
    },
    watch: {
      jobSiteTreeToggle() {
        if (!this.jobSiteTreeToggle) {
          this.mapLayer = 'jobSites';
        } else {
          this.mapLayer = 'trees';
        }
      },
    },
    // async created() {
    // },
    async created() {
      this.getAllowed();

      var tenant_uuid = this.$auth.userProfile.tenant_uuid;

      // get tenant settings
      // (in an authenticated situation we already have this info
      // via tenantProfile that gets loaded in $auth during the
      // begging of the user's session. This special case
      // is needed for the connected components that need this info
      // passed in for unsecured estimate proposal links)
      this.tenantSettings = this.$auth.tenantProfile
        ? this.$auth.tenantProfile
        : await Tenants.getSettingsInfo(
            this.$auth.userProfile.tenant_uuid
            // unsecured route
          );

      // console.log( this.tenantSettings )

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get all tenant jobsites
      var jobSiteResponse = await Clients.getJobSites(
        {
          tenant_uuid: tenant_uuid,
        },
        accessToken
      );

      // get info for jobsite tab display
      if (jobSiteResponse) {
        const clientIds = jobSiteResponse.map((job) => job.client_uuid);

        var clientsResponse = await Clients.getClientsByArray(
          tenant_uuid,
          clientIds,
          accessToken
        );

        if (clientsResponse) {
          // calculate whether the job site is associated to a business or residence
          clientsResponse.forEach((client) => {
            jobSiteResponse.forEach((jobSite) => {
              if (client.uuid == jobSite.client_uuid) {
                jobSite.client_type = client.client_type;
                jobSite.client_name = client.client_name;
                jobSite.client_uuid = client.uuid;
              }
            });
          });

          var jobSiteIds = jobSiteResponse.map((jobsite) => jobsite.uuid);

          var jobPhasesResponse = await Jobs.getJobPhasesByJobSite(
            {
              tenant_uuid: this.$auth.userProfile.tenant_uuid,
              jobSiteIds: jobSiteIds,
            },
            accessToken
          );

          if (jobPhasesResponse) {
            // calculate whether the job site is associated to a business or residence
            jobSiteResponse.forEach((jobSite) => {
              if (jobPhasesResponse[jobSite.uuid]) {
                jobSite.jobPhase = jobPhasesResponse[jobSite.uuid].phase;
                jobSite.numActiveJobs = 0;
                if (jobPhasesResponse[jobSite.uuid].jobPhases) {
                  Object.values(
                    jobPhasesResponse[jobSite.uuid].jobPhases
                  ).forEach((jobPhase) => {
                    if (jobPhase == 'Active') {
                      jobSite.numActiveJobs++;
                    }
                  });
                }
              }
            });

            // setting these will update the map
            this.clients = clientsResponse;
            this.jobSites = jobSiteResponse;
          }
        }
      }

      // get all tenant trees
      var treesResponse = await Clients.getTrees(
        {tenant_uuid: tenant_uuid},
        accessToken
      );

      // get info for tree view tab
      if (treesResponse) {
        this.trees = treesResponse;

        // translate species uuid
        for (var i = 0; i < this.trees.length; i++) {
          var tenant_species = await Tenants.getSingleTenantSpecies(
            this.trees[i].tenant_species_uuid,
            accessToken
          );

          if (tenant_species) {
            var masterResponse = await Tenants.getMasterSpeciesBySpeciesLookup({
              species_uuids: [tenant_species.species_uuid],
            });

            if (masterResponse && masterResponse[0]) {
              this.trees[i].species = masterResponse[0];
              this.trees[i].species.common_name = masterResponse[0].common_name;
            }
          }
        }
      }
    },

    methods: {
      async getAllowed() {
        this.allowJobSites = this.$auth.isAllowed('jobSites', 'view');
        this.allowClients = this.$auth.isAllowed('clients', 'view');
      },
      jobSiteClicked: function (event) {
        this.$events.$emit('jobSiteMarkerSelect', event.uuid);
      },
      treeClicked: function (event) {
        // console.log( "treeClicked", event )
        this.$events.$emit('treeMarkerSelect', event.uuid);
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
